import axios from "@/utils/request";
import { AxiosPromise } from "axios";
import { BaseResponse, ResponseStatus } from ".";

export interface StageEntity {
  stageId: string;
  tournamentId: string;
  name: string;
  number: number;
  setting: StageSettingEntity;
  brackets: BracketEntity[];
  isBracketVisible: boolean;
  state: string;
}

export enum LeagueMap {
  SummonersRift = 11,
  HowlingAbyss = 12,
}

export enum LeaguePickMode {
  Blind = 1,
  Competitive = 2,
  Random = 4,
  Tournament = 6,
}

export interface StageSettingEntity {
  numOfGroups: number;
  bestOfN: number;
  stageFormat: StageFormatType | null;
  roundRobinEncounterTimes: number;
  isAutoApproveIdenticalResult: boolean;
  isAutoCompleteBracket: boolean;
  isAutoGetGameEvidence: boolean;
  isAutoGenerateMatch: boolean;
  isAutoCheckInAllWhenCaptainCheckIn: boolean;
  isThirdPlaceMatchEnabled: boolean;
  leagueMap: LeagueMap | null;
  leaguePickMode: LeaguePickMode | null;
  draftId: string | null;
  autoApproveResultTime: number | null;
  freeForAllMaxTeamPerMatch: number | null;
}

export enum StageFormat {
  SingleElimination = "SingleElimination",
  DoubleElimination = "DoubleElimination",
  RoundRobin = "RoundRobin",
  FreeForAll = "FreeForAll",
}

export interface BracketEntity {
  name: string;
  groupNumber: number;
  state: BracketState;
  matches: BracketMatchEntity[];
}

export enum BracketState {
  Initial = "Initial",
  InProgress = "InProgress",
  PendingComplete = "PendingComplete",
  Completed = "Completed",
}

export interface BracketMatchEntity {
  id: string;
  round: number;
  tournamentTeams: any[];
  scores: BracketScoreEntity[];
  nextWinnerBracketMatchId: string;
}

export enum BracketScoreResult {
  None = "None",
  Win = "Win",
  Lose = "Lose",
  Foul = "Foul",
  NotCheckedIn = "NotCheckedIn",
  Forfeit = "Forfeit",
  Draw = "Draw",
}

export interface BracketScoreEntity {
  tournamentTeamId: string;
  winCount: number;
  totalScore: number;
  result: BracketScoreResult;
}

export enum StageState {
  Initial = "Initial",
  InProgress = "InProgress",
  StandingPendingGenerate = "StandingPendingGenerate",
  StandingPendingApproval = "StandingPendingApproval",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum StageFormatType {
  SingleElimination = "SingleElimination",
  DoubleElimination = "DoubleElimination",
  RoundRobin = "RoundRobin",
  FreeForAll = "FreeForAll",
}

export interface RoundSettingEntity {
  roundOf: number;
  name: string | null;
}

export interface BracketAdjacencyMatrixEntity {
  bracketId: string;
  groupNumber: number;
  name: string;
  state: string;
  matches: BracketMatchAdjacencyMatrixEntity[];
  additionalMatches: BracketMatchAdjacencyMatrixEntity[];
  edges: number[][];
  roundSettings: RoundSettingEntity[];
}

export interface BracketMatchAdjacencyMatrixEntity {
  bracketMatchId: string;
  matchId: string;
  teams: TournamentTeamListItemEntity[];
  scores: BracketScoreEntity[];
  checkInStartTime: number;
  checkInEndTime: number;
  isAdvanced: boolean;
  isAdditional: boolean;
  roundOf: number;
  identifier: string;
  matchGame?: BracketMatchGameEntity;
}

export interface BracketMatchGameEntity {
  currentRound: number;
  state: string;
  totalRound: number;
  checkIn: number;
  checkInTotal: number;
}

export interface TournamentTeamListItemEntity {
  tournamentTeamId: string;
  name: string;
  logo: string;
  state: string;
}

export interface StageListItemEntity {
  name: string | null;
  number: number;
  stageId: string;
  state: StageState;
}

export class StageAPI {
  public static API_PREFIX = "/tournament/v1";

  /**
   * Get stages by tournament ID
   * @param tournamentId Tournament Id
   * @returns {BaseResponse<StageListItemEntity[]>} Array of StageListItemEntity Response
   */
  public static getTournamentStages(
    tournamentId: string
  ): AxiosPromise<BaseResponse<StageListItemEntity[]>> {
    return axios.get(`${this.API_PREFIX}/tournaments/${tournamentId}/stages`);
  }

  /**
   * Get stage
   * @param tournamentId Tournament ID
   * @returns {StageEntity} StageEntity
   */
  public static getStage(
    stageId: string
  ): AxiosPromise<BaseResponse<StageEntity>> {
    return axios.get(`${this.API_PREFIX}/stages/${stageId}`);
  }
}
