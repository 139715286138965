import moment from "moment";

export function countryFlag(countryCode: string) {
  if (!countryCode || !countryCode.length) {
    return null;
  }

  switch (countryCode.toLowerCase()) {
    case "tw":
      return require("@/assets/country/tw.svg");
    case "hk":
      return require("@/assets/country/hk.svg");

    default:
      return null;
  }
}

export function sleep(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

export function unixTimestampToDateTime(unixTimestamp: number): Date {
  return moment
    .utc(unixTimestamp * 1000)
    .local()
    .toDate();
}

export function upperCase(string: string): string {
  return string.toUpperCase();
}

export function lowerCase(string: string): string {
  return string.toLowerCase();
}

export function capitalSpacing(string: string): string {
  return string.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function numberWithCommas(number: number) {
  if (!number) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function date(number: number) {
  if (isNaN(number)) return "";
  const dateTime = new Date(number * 1000);
  return [
    dateTime.getFullYear().toString().padStart(4, "0"),
    (dateTime.getMonth() + 1).toString().padStart(2, "0"),
    dateTime.getDate().toString().padStart(2, "0"),
  ].join("/");
}

export function dateTime(number: number) {
  if (isNaN(number)) return "";
  const dateTime = new Date(number * 1000);
  return (
    [
      dateTime.getFullYear().toString().padStart(4, "0"),
      (dateTime.getMonth() + 1).toString().padStart(2, "0"),
      dateTime.getDate().toString().padStart(2, "0"),
    ].join("/") +
    " " +
    [
      dateTime.getHours().toString().padStart(2, "0"),
      dateTime.getMinutes().toString().padStart(2, "0"),
    ].join(":")
  );
}
