import axios, { AxiosError } from "axios";
import ServerError from "@/utils/server_error";
import { setTimeOffset } from "@/utils/time";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 30 * 1000,
});

/* service.interceptors.request.use(
  (config) => {
    config.headers!["Accept-Language"] = i18n.locale;
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  }
); */

// Response code handling : Response Interceptor
service.interceptors.response.use(
  (response) => {
    if (response.data.serverTime) {
      setTimeOffset(new Date(response.data.serverTime * 1000));
    }

    return response;
  },
  async (error) => {
    if (error.isAxiosError) {
      return Promise.reject(new ServerError(error));
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
