import axios from "@/utils/request";
import { AxiosPromise } from "axios";
import { BaseResponse, ResponseStatus } from ".";
import { TournamentTeamEntity } from "./tournamentTeam";

export { BaseResponse, ResponseStatus };

export class TournamentAPI {
  public static API_PREFIX = "/tournament/v1";

  /**
   * Get tournament team
   * @param tournamentTeamId Tournament Team ID
   * @returns {BaseResponse<TournamentTeamEntity>} API Response
   */
  public static getTournamentTeam(
    tournamentTeamId: string
  ): AxiosPromise<BaseResponse<TournamentTeamEntity>> {
    return axios.get(`${this.API_PREFIX}/tournament-teams/${tournamentTeamId}`);
  }
}
