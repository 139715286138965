




























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { StageAPI, StageEntity, StageListItemEntity } from "@/api/stage";
import TournamentTeamDialog from "@/components/TournamentTeamDialog/TournamentTeamDialog.vue";
@Component({
  components: { TournamentTeamDialog },
})
export default class Bracket extends Vue {
  @Prop() readonly tournamentId!: string;

  @Watch("selectedStageId")
  onSelectedStageIdChanged() {
    this.fetchStage(this.selectedStageId!).then();
  }

  loading = true;
  error = false;
  stages: StageListItemEntity[] = [];
  stage: StageEntity | null = null;
  selectedStageId: string | null = null;
  selectedTournamentTeamId = "";
  isTournamentTeamDialogVisible = false;

  created() {
    this.fetchStages().then();
  }

  async fetchStages() {
    this.loading = true;

    try {
      const res = await StageAPI.getTournamentStages(this.tournamentId);
      this.stages = res.data.payload!;

      if (!this.stages[0]) {
        return;
      }

      this.selectedStageId = this.stages[0].stageId;
    } catch (err) {
      console.error(err);
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  async fetchStage(stageId: string) {
    this.loading = true;

    try {
      const res = await StageAPI.getStage(stageId);
      this.stage = res.data.payload!;
    } catch (err) {
      console.error(err);
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  get stageItems() {
    return this.stages.map((stage) => ({
      text: stage.name,
      value: stage.stageId,
    }));
  }

  get brackets() {
    if (!this.stage) {
      return [];
    }

    return this.stage!.brackets!.sort((a, b) => a.groupNumber - b.groupNumber);
  }

  get ios() {
    return [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform);
  }

  getIsDisplayStageBracket(stage: StageEntity) {
    if (stage.state === "Initial" || !stage.isBracketVisible) return false;
    return true;
  }

  getStageBracketInvisibleMessage(stage: StageEntity) {
    if (stage.state === "Initial") return "賽程準備中";
    else if (!stage.isBracketVisible) return "管理員正在處理賽程中";
    return "";
  }

  onBracketClick(e: any) {
    if (e.header || e.footer || !e.team) return;
    this.selectedTournamentTeamId = e.team.tournamentTeamId;
    this.isTournamentTeamDialogVisible = true;
  }

  closeTournamentTeamDialog() {
    this.isTournamentTeamDialogVisible = false;
    this.selectedTournamentTeamId = "";
  }
}
