let timeOffset = 0

export function setTimeOffset(serverTime: Date) {
  timeOffset = serverTime.getTime() - new Date().getTime()
}

export function getTimeOffset() {
  return timeOffset
}

export function getSecondsDiff(a: any, b: any) {
  const timeStampA = a instanceof Date ? a.getTime() : a
  const timeStampB = b instanceof Date ? b.getTime() : b

  return Math.ceil((timeStampA - timeStampB + timeOffset) / 1000)
}

export function now(): Date {
  return new Date(new Date().getTime() + getTimeOffset())
}

export default {
  setTimeOffset,
  getTimeOffset,
  getSecondsDiff
}
