




import { Vue, Component } from "vue-property-decorator";

@Component
export default class NotFound extends Vue {
  created() {
    if (process.env.NODE_ENV !== "development") {
      window.location.href = "https://vsarena.gg";
    }
  }
}
