export interface BaseResponse<T = undefined> {
  status: string
  message?: string
  payload?: T
  serverTime: string
}

export interface Pagination<T = undefined> {
  items: T[]
  hasMorePage: boolean
}

export interface PaginationWithPageCount<T = undefined> {
  items: T[]
  hasMorePage: boolean
  itemCount: number
  pageCount: number
}

export enum ResponseStatus {
  Ok = 'ok',
  Error = 'error',
  Fail = 'fail',
  UpdateRequired = 'update_required'
}
