export interface TournamentTeamEntity {
  tournamentId: string;
  tournamentTeamId: string;
  name: string;
  logo: string;
  state: TournamentTeamState;
  registerTime: number;
  players: TournamentPlayerEntity[];
  isOut: boolean;
  outTime: number;
  isTournamentCheckedIn: boolean;
  tournamentCheckInTime: number | null;
  isTournamentNotCheckedInUnregistered: boolean;
  group: string | null;
}

export enum TournamentTeamState {
  PendingJoin = "PendingJoin",
  PendingConfirmRoster = "PendingConfirmRoster",
  Registered = "Registered",
}

export interface TournamentPlayerEntity {
  memberId: string;
  nickname: string;
  avatar: string;
  role: TournamentTeamRole;
  isStarter: boolean;
  gameAccountNickname: string;
}

export enum TournamentTeamRole {
  Captain = "Captain",
  Member = "Member",
  RequestToJoin = "RequestToJoin",
}
