import { AxiosError } from "axios";
import { ResponseStatus } from "@/api";

class ServerError extends Error {
  axiosError: AxiosError;

  constructor(axiosError: AxiosError) {
    super();
    this.axiosError = axiosError;
    this.name = "ServerError";
    this.message = "Server returns an error. Check Network for more details.";

    if (axiosError.response && axiosError.response!.status === 500) {
      console.error(
        "!!! INTERNAL SERVER ERROR !!!\n",
        axiosError.response!.data
      );
    }
  }

  getStatusCode(): number {
    return this.axiosError.response ? this.axiosError.response!.status : 0;
  }

  getMessage(): string {
    if (
      !this.axiosError.response ||
      !this.axiosError.response!.data ||
      !this.axiosError.response!.data.status
    ) {
      if (
        this.axiosError.response &&
        this.axiosError.response!.status === 500
      ) {
        return "Internal server error";
      } else {
        return "Server is unreachable. Please check your network connection.";
      }
    }

    const data = this.axiosError.response!.data;
    let msg: string;

    switch (data.status) {
      case ResponseStatus.Error:
        return data.message;

      case ResponseStatus.Fail:
        msg = "Client error. Invalid request.";

        Object.keys(data.payload).forEach((propertyName) => {
          data.payload[propertyName].forEach((error: string) => {
            msg += `\n[${propertyName}]: ${error}`;
          });
        });

        return msg;

      case ResponseStatus.UpdateRequired:
        return "Software update is required.";

      default:
        return "Client error. Invalid response status.";
    }
  }
}

export default ServerError;
export { ServerError };
