

















































































































import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import {
  TournamentTeamEntity,
  TournamentTeamRole,
  TournamentTeamState,
} from "@/api/tournamentTeam";
import { ResponseStatus, TournamentAPI } from "@/api/tournament";
import { dateTime } from "@/utils";

@Component({
  filters: {
    dateTime,
  },
})
export default class TournamentTeamDialog extends Vue {
  @PropSync("isVisible", { type: Boolean, default: false })
  isModalVisible!: boolean;

  @Prop({ required: true })
  readonly tournamentTeamId!: string;

  isLoading = false;
  tournamentTeam: TournamentTeamEntity | null = null;
  defaultAvatar = "/img/logo-v13.png";

  /* Watchers */
  @Watch("isModalVisible")
  async onVisible(isVisible: boolean) {
    await this.getTournamentTeam();
  }

  /* Methods */
  async getTournamentTeam() {
    if (!this.tournamentTeamId) return;

    this.tournamentTeam = null;
    this.isLoading = true;
    try {
      const response = await TournamentAPI.getTournamentTeam(
        this.tournamentTeamId
      );
      const payload = response.data.payload;
      if (response.data.status === ResponseStatus.Ok && payload) {
        this.tournamentTeam = payload;
      }
    } catch (err) {
      alert("網絡錯誤");
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  closeDialog() {
    this.$emit("close");
  }

  /* Computed Properties */
  get TournamentTeamRole() {
    return TournamentTeamRole;
  }

  get tournamentTeamPlayers() {
    if (!this.tournamentTeam || !this.tournamentTeam.players) return [];
    return this.tournamentTeam.players.filter(
      (player: any) => player.role !== TournamentTeamRole.RequestToJoin
    );
  }

  get statusBarColor() {
    if (
      !this.tournamentTeam ||
      this.tournamentTeam.state === TournamentTeamState.PendingJoin
    )
      return "grey darken-2";
    if (this.tournamentTeam.state === TournamentTeamState.PendingConfirmRoster)
      return "secondary";
    else return "primary";
  }

  get statusBarText() {
    if (
      !this.tournamentTeam ||
      this.tournamentTeam.state === TournamentTeamState.PendingJoin
    )
      return "等待隊員加入中";
    if (this.tournamentTeam.state === TournamentTeamState.PendingConfirmRoster)
      return "等待隊長確認出賽名單中";
    else return "已成功報名";
  }

  get statusBarIcon() {
    if (
      !this.tournamentTeam ||
      this.tournamentTeam.state === TournamentTeamState.PendingJoin
    )
      return "mdi-account-search";
    if (this.tournamentTeam.state === TournamentTeamState.PendingConfirmRoster)
      return "mdi-account-clock";
    else return "mdi-account-check";
  }
}
